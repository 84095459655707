import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4901193e = () => interopDefault(import('../pages/ban-hang-doanh-nghiep.vue' /* webpackChunkName: "pages/ban-hang-doanh-nghiep" */))
const _25f1d254 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _299d050e = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _c4f89b22 = () => interopDefault(import('../pages/category/index.vue' /* webpackChunkName: "pages/category/index" */))
const _1c95b386 = () => interopDefault(import('../pages/chinh-sach-bao-hanh.vue' /* webpackChunkName: "pages/chinh-sach-bao-hanh" */))
const _086102e2 = () => interopDefault(import('../pages/collection/index.vue' /* webpackChunkName: "pages/collection/index" */))
const _6eb2cdd8 = () => interopDefault(import('../pages/dieu-khoan-su-dung-chinh-sach-bao-mat.vue' /* webpackChunkName: "pages/dieu-khoan-su-dung-chinh-sach-bao-mat" */))
const _415a6cd4 = () => interopDefault(import('../pages/error_page.vue' /* webpackChunkName: "pages/error_page" */))
const _750906fc = () => interopDefault(import('../pages/gioi-thieu.vue' /* webpackChunkName: "pages/gioi-thieu" */))
const _4123dbda = () => interopDefault(import('../pages/ho-tro-khach-hang.vue' /* webpackChunkName: "pages/ho-tro-khach-hang" */))
const _325c91f0 = () => interopDefault(import('../pages/my_account.vue' /* webpackChunkName: "pages/my_account" */))
const _6880f118 = () => interopDefault(import('../pages/not_found.vue' /* webpackChunkName: "pages/not_found" */))
const _2e5e04d3 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _543823d4 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _79e43a8e = () => interopDefault(import('../pages/thong-tin-lien-he.vue' /* webpackChunkName: "pages/thong-tin-lien-he" */))
const _6b41bed4 = () => interopDefault(import('../pages/tra-hang-hoan-tien.vue' /* webpackChunkName: "pages/tra-hang-hoan-tien" */))
const _5f9a9d54 = () => interopDefault(import('../pages/blogs/search.vue' /* webpackChunkName: "pages/blogs/search" */))
const _258985aa = () => interopDefault(import('../pages/product/favorite.vue' /* webpackChunkName: "pages/product/favorite" */))
const _19c727ee = () => interopDefault(import('../pages/product/old.vue' /* webpackChunkName: "pages/product/old" */))
const _6d1739d3 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _295f14e4 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _c865ddb2 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _c2758c9e = () => interopDefault(import('../pages/checkout/_code.vue' /* webpackChunkName: "pages/checkout/_code" */))
const _ba0c4e92 = () => interopDefault(import('../pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _c0cba3c8 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _3d99634c = () => interopDefault(import('../pages/san-pham/_slug.vue' /* webpackChunkName: "pages/san-pham/_slug" */))
const _21f1255e = () => interopDefault(import('../pages/store/_slug.vue' /* webpackChunkName: "pages/store/_slug" */))
const _6060b458 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _652233e1 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ban-hang-doanh-nghiep",
    component: _4901193e,
    name: "ban-hang-doanh-nghiep"
  }, {
    path: "/blogs",
    component: _25f1d254,
    name: "blogs"
  }, {
    path: "/cart",
    component: _299d050e,
    name: "cart"
  }, {
    path: "/category",
    component: _c4f89b22,
    name: "category"
  }, {
    path: "/chinh-sach-bao-hanh",
    component: _1c95b386,
    name: "chinh-sach-bao-hanh"
  }, {
    path: "/collection",
    component: _086102e2,
    name: "collection"
  }, {
    path: "/dieu-khoan-su-dung-chinh-sach-bao-mat",
    component: _6eb2cdd8,
    name: "dieu-khoan-su-dung-chinh-sach-bao-mat"
  }, {
    path: "/error_page",
    component: _415a6cd4,
    name: "error_page"
  }, {
    path: "/gioi-thieu",
    component: _750906fc,
    name: "gioi-thieu"
  }, {
    path: "/ho-tro-khach-hang",
    component: _4123dbda,
    name: "ho-tro-khach-hang"
  }, {
    path: "/my_account",
    component: _325c91f0,
    name: "my_account"
  }, {
    path: "/not_found",
    component: _6880f118,
    name: "not_found"
  }, {
    path: "/orders",
    component: _2e5e04d3,
    name: "orders"
  }, {
    path: "/search",
    component: _543823d4,
    name: "search"
  }, {
    path: "/thong-tin-lien-he",
    component: _79e43a8e,
    name: "thong-tin-lien-he"
  }, {
    path: "/tra-hang-hoan-tien",
    component: _6b41bed4,
    name: "tra-hang-hoan-tien"
  }, {
    path: "/blogs/search",
    component: _5f9a9d54,
    name: "blogs-search"
  }, {
    path: "/product/favorite",
    component: _258985aa,
    name: "product-favorite"
  }, {
    path: "/product/old",
    component: _19c727ee,
    name: "product-old"
  }, {
    path: "/checkout/success/:id?",
    component: _6d1739d3,
    name: "checkout-success-id"
  }, {
    path: "/blogs/:slug",
    component: _295f14e4,
    name: "blogs-slug"
  }, {
    path: "/category/:slug",
    component: _c865ddb2,
    name: "category-slug"
  }, {
    path: "/checkout/:code?",
    component: _c2758c9e,
    name: "checkout-code"
  }, {
    path: "/collection/:id",
    component: _ba0c4e92,
    name: "collection-id"
  }, {
    path: "/product/:id?",
    component: _c0cba3c8,
    name: "product-id"
  }, {
    path: "/san-pham/:slug?",
    component: _3d99634c,
    name: "san-pham-slug"
  }, {
    path: "/store/:slug?",
    component: _21f1255e,
    name: "store-slug"
  }, {
    path: "/",
    component: _6060b458,
    name: "index"
  }, {
    path: "/*",
    component: _652233e1,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
